<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="loading" class="skeleton"></div>
  <i
    class="nuxt-icon"
    :class="$attrs.class"
    :style="styleValue"
    @click="$emit('click')"
    v-html="icon"
  />
  <!-- <span v-if="error" class="skeleton"></span> -->
</template>

<script setup lang="ts">
import { ref } from 'vue'
const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  styleValue: {
    type: String,
    default: '',
  },
})

const loading = ref(true)
// const error = ref(false)
const icon = ref<any>('')
// const runtimeConfig = useRuntimeConfig().public

// const getTextUrl = async () => {
//   try {
//     const url = `${runtimeConfig.DEV_BUCKET_URL}test/static/icons/${props.name}.svg`
//     let response = await fetch(url)
//     const myText = await response.text()
//     icon.value = myText

//     if (myText.includes('NoSuchKey')) {
//       error.value = true
//     } else {
//       loading.value = false
//     }
//   } catch {
//     loading.value = false
//     console.warn(`[nuxt-icons] Icon '${props.name}' doesn't exist in`)
//   }
// }
// onMounted(() => {
//   getTextUrl()
// })

// defineEmits(['click'])
defineEmits(['click'])

try {
  const iconsImport = import.meta.glob('assets/icons/**.svg', {
    as: 'raw',
    eager: false,
  })
  const rawIcon = await iconsImport[`/assets/icons/${props.name}.svg`]()
  icon.value = rawIcon
  if (rawIcon.includes('exist')) {
    // error.value = true
  } else {
    loading.value = false
  }
} catch {
  console.warn(
    `[nuxt-icons] Icon '${props.name}' doesn't exist in 'assets/icons'`,
  )
}
</script>

<style lang="scss" scoped>
.skeleton {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-squares 2s infinite ease-out;
}

@keyframes wave-squares {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
</style>
